<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card">
            <div class="card-header">
                <h5>
                    سحب الرصيد
                </h5>
            </div>
            <div class="card-body">
                <div class="g">
                    <h4 class="alert alert-primary text-center g">
                        المبلغ المراد سحبه: {{ withdrawal.toFixed(1) }} ريال
                    </h4>
                </div>
                <div class="form-group">
                  <h5 for="">الحساب البنكي أو الطريقة التي تريد الإستلام بها</h5>
                  <textarea class="form-control" v-model="bank" rows="3"></textarea>
                </div>
                <div class="form-group">
                  <h5 for="">هل تود اضافة اي ملاحظات؟</h5>
                  <textarea class="form-control" v-model="notes" rows="3"></textarea>
                </div>
                <div class="col-12 text-center g">
                    <button class="btn btn-primary" @click="done()">
                        ارسال طلب سحب الان
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            withdrawal: 0,
            bank: "",
            notes: ""
        }
    },
    created(){
        var g = this;
        $.post(api + '/reseller/stats', {
            jwt: this.user.jwt
        }).then(function(r){
            g.withdrawal = r.response.withdrawal;
        })
    },
    methods: {
        done(){
            if(confirm("متأكد من ارسال طلب سحب؟")){
                var g = this;
                if(g.withdrawal >= 0){
                    $.post(api + '/reseller/withdraw', {
                        jwt: this.user.jwt,
                        bank: g.bank,
                        notes: g.notes
                    }).then(function(r){
                        alert("تم ارسال الطلب بنجاح", 100)
                    })
                }else{
                    alert("رصيدك 0", 200)
                }
            }
        }
    }
}
</script>

<style>

</style>